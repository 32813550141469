import { createEvent } from 'effector';

export const setOpenCountryModal = createEvent<boolean>('setOpenCountryModal');

export const setSelectedCountry = createEvent<string>('setSelectedCountry');

export const setSelectedVendor = createEvent<string>('setSelectedVendor');

export const setForceDownloadTab = createEvent<boolean>('setForceDownloadTab');

export const setSendToDownloadTab = createEvent<boolean>('setForceDownloadTab');

export const setInventoryDisableDownloadButton = createEvent<boolean>('setInventoryDisableDownloadButton');
