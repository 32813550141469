import { ROUTER_BASENAME } from './constants';

export const HOME_ROUTE = ROUTER_BASENAME;
export const NOT_FOUND_ROUTE = `${ROUTER_BASENAME}/not-found`;
export const SUMMARY_ROUTE = `${ROUTER_BASENAME}/summary`;
export const CLIENT_MANAGEMENT_ROUTE = `${ROUTER_BASENAME}/client-management`;

export const LIMITS_PER_CLIENT_ROUTE = `${ROUTER_BASENAME}/limits-per-client`;

export const ATP_SUMMARY_ROUTE = `${ROUTER_BASENAME}/stock-balance`;
export const ATP_PARAMETERS_ROUTE = `${ROUTER_BASENAME}/atp-parameters`;
export const STOCK_RESERVATION_FORM_ROUTE = `${ROUTER_BASENAME}/atp-parameters/form`;

export const CRITICAL_INVENTORY_ROUTE = `${ROUTER_BASENAME}/critical-stock-management/critical-level`;
export const RULES_MANAGEMENT_ROUTE = `${ROUTER_BASENAME}/critical-stock-management/rules`;
export const RULES_MANAGEMENT_FORM_ROUTE = `${ROUTER_BASENAME}/critical-stock-management/rules/form`;

export const FILE_MONITORING_ROUTE = `${ROUTER_BASENAME}/file-monitoring`;

