import permissionRoutes from 'config/permissionRoutes';
import { CLIENT_MANAGEMENT_ROUTE, LIMITS_PER_CLIENT_ROUTE } from 'config/routes';
import { useIntl } from 'react-intl';
import { CheckOnePermission } from 'utils/userHasPermission/userHasPermission';
import { AtpParametersIcon, AvailabilityIcon, ClientManagementIcon, CriticalStockIcon, FileMonitoringIcon, LimitsPerClientIcon, StockBalanceIcon, SummaryIcon } from './SideBarIcons';

interface ISidebarLinks {
    id: string;
    path: string;
    icon: any;
    title: string;
}

const sideBarItems = (): Array<ISidebarLinks> => {
    const { formatMessage } = useIntl();

    const items: { [pathId: string]: ISidebarLinks } = {
        '/availability': {
            id: '1',
            title: formatMessage({ id: 'sideMenu.home' }),
            icon: AvailabilityIcon,
            path: '/availability',
        },
        '/availability/summary': {
            id: '2',
            title: formatMessage({ id: 'sideMenu.summary' }),
            icon: SummaryIcon,
            path: '/availability/summary',
        },
        '/availability/limits-per-client': {
            id: '3',
            title: formatMessage({ id: 'sideMenu.limitPerClient' }),
            icon: LimitsPerClientIcon,
            path: '/availability/limits-per-client',
        },
        '/availability/client-management': {
            id: '4',
            title: formatMessage({ id: 'sideMenu.clientManagement' }),
            icon: ClientManagementIcon,
            path: '/availability/client-management',
        },
        '/availability/stock-balance': {
            id: '5',
            title: formatMessage({ id: 'sideMenu.summaryInventory' }),
            icon: StockBalanceIcon,
            path: '/availability/stock-balance',
        },
        '/availability/critical-stock-management/rules': {
            id: '6',
            title: formatMessage({ id: 'sideMenu.criticalInventory' }),
            icon: CriticalStockIcon,
            path: '/availability/critical-stock-management/rules',
        },
        '/availability/atp-parameters': {
            id: '7',
            title: formatMessage({ id: 'sideMenu.atpParameters' }),
            icon: AtpParametersIcon,
            path: '/availability/atp-parameters',
        },
        '/availability/file-monitoring': {
            id: '8',
            title: formatMessage({ id: 'sideMenu.fileMonitoring' }),
            icon: FileMonitoringIcon,
            path: '/availability/file-monitoring',
        },
    };

    let sideBarLinks: Array<ISidebarLinks> = [];

    /* Check permission for availability route */
    const pathHome = '/availability';
    if (CheckOnePermission(permissionRoutes[pathHome])) {
        sideBarLinks.push(items[pathHome]);
    }
    const pathSummary = '/availability/summary';
    if (CheckOnePermission(permissionRoutes[pathSummary])) {
        sideBarLinks.push(items[pathSummary]);
    }

    if (CheckOnePermission(permissionRoutes[LIMITS_PER_CLIENT_ROUTE])) {
        sideBarLinks.push(items[LIMITS_PER_CLIENT_ROUTE]);
    }

    if (CheckOnePermission(permissionRoutes[CLIENT_MANAGEMENT_ROUTE])) {
        sideBarLinks.push(items[CLIENT_MANAGEMENT_ROUTE]);
    }

    const pathATP = '/availability/stock-balance';
    if (CheckOnePermission(permissionRoutes[pathATP])) {
        sideBarLinks.push(items['/availability/stock-balance']);
    }

    const pathFileMonitoring = '/availability/file-monitoring';
    if (CheckOnePermission(permissionRoutes[pathFileMonitoring])) {
        sideBarLinks.push(items[pathFileMonitoring]);
    }

    const pathATPParameters = '/availability/atp-parameters';
    if (CheckOnePermission(permissionRoutes[pathATPParameters])) {
        sideBarLinks.push(items['/availability/atp-parameters']);
    }

    const pathRiskAvailability = '/availability/critical-stock-management/rules';
    if (CheckOnePermission(permissionRoutes[pathRiskAvailability])) {
        sideBarLinks.push(items['/availability/critical-stock-management/rules']);
    }

    return sideBarLinks;
};

export default sideBarItems;
