import permissionScopes from './permissionScopes';

const permissionRoutes: { [pathId: string]: Array<string> } = {
  '/availability': [
    permissionScopes.IMPORT_LIMITS_PER_DDC,
    permissionScopes.IMPORT_LIMITS_PER_CLIENT,
  ],
  '/availability/summary': [
    permissionScopes.MANAGE_LIMITS_PER_DDC,
    permissionScopes.WRITE_DDC_EXCEPTION,
    permissionScopes.READ_LIMITS_PER_DDC,
    permissionScopes.IMPORT_LIMITS_PER_DDC,
  ],

  '/availability/stock-balance': [permissionScopes.READ_ATP_PAGE],

  '/availability/limits-per-client': [
    permissionScopes.CONSULT_POC_LIMITS_READ
  ],

  '/availability/atp-parameters': [
    permissionScopes.READ_ATP_PARAMETERS_PAGE,
    permissionScopes.WRITE_ATP_PARAMTERS_PAGE
  ],
  '/availability/atp-parameters/form': [
    permissionScopes.READ_ATP_PARAMETERS_PAGE,
    permissionScopes.WRITE_ATP_PARAMTERS_PAGE
  ],

  '/availability/file-monitoring': [
    permissionScopes.INVENTORY_REPORT_DOWNLOAD,
    permissionScopes.ORDERS_REPORT_DOWNLOAD
  ],

  '/availability/client-management': [
    permissionScopes.WRITE_DDC_EXCEPTION
  ],

  '/availability/critical-stock-management': [
    permissionScopes.READ_RISK_AVAILABILITY,
    permissionScopes.WRITE_RISK_AVAILABILITY
  ],
  '/availability/critical-stock-management/critical-level': [
    permissionScopes.READ_RISK_AVAILABILITY,
    permissionScopes.WRITE_RISK_AVAILABILITY
  ],
  '/availability/critical-stock-management/rules': [
    permissionScopes.READ_RISK_AVAILABILITY,
    permissionScopes.WRITE_RISK_AVAILABILITY
  ],
  '/availability/critical-stock-management/rules/form': [
    permissionScopes.READ_RISK_AVAILABILITY,
    permissionScopes.WRITE_RISK_AVAILABILITY
  ],
};

export default permissionRoutes;
